.carouselContainer {
  background-color: #5ebed7;
  border-radius: 5%;
  margin-top: 20px;
  padding: 20px;
}

.slider_image {
  margin: auto;
}

.slider_footer {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
