.checkbox-label {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  
  .checkbox-label input {
    opacity: 0;
    position: absolute;
  }
  
  .checkbox {
    width: 20px; /* Adjust the size of the custom checkbox */
    height: 20px;
    border: 2px solid #333; /* Adjust the border color */
    border-radius: 50%;
    margin-right: 8px; /* Adjust the spacing between the custom checkbox and the label text */
    background-color: #fff; /* Adjust the background color */
    display: inline-block;
  }
  
  .checkbox-label input:checked + .checkbox {
    background-color: #5ebed7; /* Adjust the background color when checked */
  }
  